
import './App.css';
import MainRoutes from './MainRoutes';

function App() {
	return (
		<>
			<MainRoutes />
		</>
	);
}

export default App;
